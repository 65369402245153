import React from 'react'

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import celebrate from '../images/celebrate.svg'

const ContactPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Header
      title="Get in touch"
      sub="We can't wait to hear from you. Fill out the contact form to get in contact with us."
      image={celebrate}
      form
    />
  </Layout>
)

export default ContactPage
